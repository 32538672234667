import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { ProductType } from 'src/home_page/components/ProductPop/ProductPop'
import { ContentMediaType } from 'src/home_page/components/ContentPop/ContentPop'
import { IconType } from 'src/design_system/atoms/icons/types'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { ImageDiffSliderProps } from 'src/design_system/components/ImageDiffSlider/ImageDiffSlider'
import { BentoContainer } from './BentoContainer'
import { BlobSettingType } from 'src/design_system/components/GlassBackgroundSvgContainer/GlassBackgroundSvgContainer'

export type BentoExpandType = BentoContentExpandType | ProductType

export type BentoContentExpandType = {
  type: 'content'
  bannerImages: string[]
  contentStream: ContentMediaType[]
  imageDiff?: ImageDiffSliderProps
}

export type BentoGridArea = {
  colStart: string
  colEnd: string
  rowStart: string
  rowEnd: string
}

export type BentoCardData = {
  id: number
  grid?: BentoGridArea
  title?: string
  description?: string
  icon: IconType
  pill?: string
  details?: BentoExpandType
  isFullWidth?: boolean
}

type BentoCardProps = {
  bento: BentoCardData
  onSelect: (id: number) => void
  onLearnMore?: (id?: number) => void
  isActive: boolean
  width?: string
  height?: string
  isMobile?: boolean
  isSmallMobile?: boolean
  activeGradient?: string
  blobs?: BlobSettingType
}

export const BentoCard: React.FC<BentoCardProps> = ({
  bento,
  onSelect,
  onLearnMore,
  isActive,
  width,
  height,
  isMobile,
  isSmallMobile,
  activeGradient,
  blobs,
}) => {
  return (
    <BentoContainer
      onClick={() => onSelect(bento.id)}
      blobs={blobs ?? { option: 0, width: '100%', height: '100%' }}
      activeGradient={activeGradient}
      isActive={false}
      width={width}
      height={height}
      isSmallMobile={isSmallMobile}
    >
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Icon name={bento.icon} />
        <Text
          type={isMobile ? 'caption' : 'body'}
          content={bento.title}
          weight="bold"
          style={{ fontSize: '14px', fontWeight: 700 }}
        />
      </Flex>
      <Flex width="100%">
        <Text
          type={isMobile ? 'footnote' : 'caption'}
          content={bento.description}
          style={{ fontSize: '12px', fontWeight: 500 }}
        />
      </Flex>
      {onLearnMore && (
        <Flex
          width="100%"
          justifyContent="flex-start"
          onClick={() => onLearnMore?.(bento.id)}
          cursor="pointer"
          alignItems="self-end"
          outset={{ top: 'auto' }}
        >
          <Text
            outset={{ top: 'x1' }}
            type={isMobile ? 'footnote' : 'caption'}
            weight="bold"
            lineHeight={1}
            style={{
              color: '#02a9e0',
              fontSize: '12px',
              fontWeight: 500,
              cursor: 'pointer',
            }}
            content="Learn More"
          />
        </Flex>
      )}
    </BentoContainer>
  )
}
