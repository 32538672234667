import React from 'react'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import useContainerWidth from 'src/hooks/useContainerWidth'

export const HomePageFooter: React.FC = () => {
  const [containerrRef, containerWidth] = useContainerWidth()
  const anchorData = [
    {
      title: 'Fair Use Policy',
      link: ' https://www.sendoutcards.com/resources/fair-use-policy-examples/',
    },
    {
      title: 'Customer Terms & Conditions',
      link:
        'https://www.sendoutcards.com/resources/documents/710/Promptings_Customer_Agreement_Documents_November_2023.pdf',
    },
    {
      title: 'Privacy Policy',
      link:
        'https://www.sendoutcards.com/resources/documents/349/SendOutCards_Privacy_Policy.pdf',
    },
    {
      title: 'Customer Referral Program',
      link:
        'https://help.sendoutcards.com/sendoutcards/en/articles/6602203-what-is-the-promptings-customer-referral-program',
    },
  ]

  const isInitialBreakPoint = containerWidth <= 1118
  const isTablet = containerWidth <= 858
  const isMobile = containerWidth <= 740

  return (
    <Div
      ref={containerrRef}
      display="flex"
      width="100%"
      style={{
        background:
          'linear-gradient(180deg, #FFFFFF 0%, #F7F2F9 50%, #EBE5F0 100%)',
        backdropFilter: 'blur(200px)',
      }}
      backgroundSize="cover"
      inset={{
        vertical: isMobile ? '2.625rem' : '4.625rem',
        horizontal: containerWidth <= 1297 ? '6rem' : '12.5rem',
      }}
      flexDirection="column"
      rowGap="x3"
    >
      <Flex
        justifyContent={isMobile ? 'initial' : 'space-between'}
        flexDirection={isMobile ? 'column' : undefined}
        alignItems={isMobile ? 'flex-start' : 'initial'}
        rowGap={isMobile ? 'x2' : 'x0'}
      >
        <Flex flexDirection={isMobile ? undefined : 'column'}>
          {!isMobile && (
            <Flex
              justifyContent="flex-start"
              cursor="pointer"
              height="90px"
              width="290px"
            >
              <Icon name={'promptings_powered_by_soc'} />
            </Flex>
          )}
          <Flex
            alignItems={isMobile ? 'center' : undefined}
            flexDirection="row"
            columnGap="x2"
            inset={{ top: 'x1' }}
          >
            <Icon
              name="facebook"
              size={30}
              color="#000"
              onClick={() =>
                window.open('https://www.facebook.com/SendOutCards')
              }
            />
            <Icon
              name="instagram"
              size={30}
              color="#000"
              onClick={() =>
                window.open('https://www.instagram.com/sendoutcards_official/')
              }
            />
            <Icon
              name="linked_in"
              size={30}
              color="#000"
              onClick={() =>
                window.open('https://www.linkedin.com/company/send-out-cards/')
              }
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems={isMobile ? 'initial' : 'flex-end'}
        >
          <Text
            type="body"
            content="801-463-3800"
            style={{
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              color: '#404040',
            }}
          />
          <Text
            type="body"
            content="support@promptings.com"
            style={{
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              color: '#404040',
            }}
          />
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        flexDirection={isTablet ? 'column' : undefined}
        alignItems={isTablet ? 'center' : undefined}
        rowGap={isTablet ? 'x1' : 'x0'}
      >
        {!isMobile && (
          <Div
            display={isInitialBreakPoint ? 'grid' : 'flex'}
            flexDirection="row"
            columnGap="x2"
            style={{
              gridTemplateColumns: isInitialBreakPoint
                ? 'repeat(2, minmax(260px, 1fr))'
                : 'unset',
              rowGap: isInitialBreakPoint ? '.5rem' : 'unset',
            }}
          >
            {anchorData.map((data, i) => {
              return (
                <a
                  key={i}
                  href={data.link}
                  style={{
                    textDecoration: 'underline',
                    color: '#404040',
                    fontWeight: 700,
                    fontFamily: 'Montserrat',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data.title}
                </a>
              )
            })}
          </Div>
        )}
        <Text
          type="body"
          content="Copyright 2024 ®"
          style={{
            fontSize: '1rem',
            fontFamily: 'Inter',
            color: '#1c1c1c',
            whiteSpace: 'nowrap',
          }}
        />
      </Flex>
    </Div>
  )
}
