import React from 'react'
import { Cancel } from '../Cancel'
import { Card } from '../Card'
import { Edit } from '../Edit'
import { Contact } from '../Contact'
import { EmptyGift } from '../EmptyGift'
import { Gift } from '../Gift'
import { Replace } from '../Replace'
import { IconProps } from '../types'
import { Upload } from '../Upload'
import { User } from '../User'
import { ShoppingCart } from '../ShoppingCart'
import { PaperPlane } from '../PaperPlane'
import { Danger } from '../Danger'
import { Info } from '../Info'
import { VCheck } from '../VCheck'
import { PaperPlaneWithHeart } from '../PaperPlaneWithHeart'
import { Database } from '../Database'
import { DoubleCheck } from '../DoubleCheck'
import { Check } from '../Check'
import { X } from '../X'
import { Search } from '../Search'
import { Package } from '../Package'
import { MultipleCards } from '../MultipleCards'
import { PaperPlaneFlying } from '../PaperPlaneFlying'
import { Brochure } from '../Brochure'
import { Cards10 } from '../Cards10'
import { Cards15 } from '../Cards15'
import { Cards20 } from '../Cards20'
import { Cards25 } from '../Cards25'
import { Cards30 } from '../Cards30'
import { Heartfelt } from '../Heartfelt'
import { CardError } from '../CardError'
import { SmileCard } from '../SmileCard'
import { SmileCardWHearts } from '../SmileCardWHearts'
import { SmileCardWHeart } from '../SmileCardWHeart'
import { SoloPreneur } from '../Solopreneur'
import { BusinessPreneur } from '../BusinessPreneur'
import { EntrePreneur } from '../EntrePreneur'
import { CardPack } from '../CardPack'
import { TimeBlock } from '../TimeBlock'
import { ExpCalendar } from '../ExpCalendar'
import { CheckMark } from '../CheckMark'
import { Ticket } from '../ticket'
import { AutomatedCard } from '../AutomatedCard'
import { CampaignCard } from '../CampaignCard'
import { GroupSendCard } from '../GroupSendCard'
import { ScheduleCard } from '../ScheduleCard'
import { MultiTouchCard } from '../MultiTouchCard'
import { CatalogCard } from '../CatalogCard'
import { EnhancedStorage } from '../EnhancedStorage'
import { PremiumStorage } from '../PremiumStorage'
import { PhotoDropCard } from '../PhotoDropCard'
import { PersonalSignatures } from '../PersonalSignatures'
import { PersonalHandWriting } from '../PersonalHandWriting'
import { EssentialPacks } from '../EssentialPacks'
import { GooglePlayStore } from '../GooglePlayStore'
import { AppleStore } from '../AppleStore'
import { PromptingsLogo } from '../PromtingsLogo'
import { PlayButton } from '../PlayButton'
import { SOCLogo } from '../SOCLogo'
import { PromptingsLogoFull } from '../PromptingsLogo'
import { FaceBook } from '../FaceBook'
import { Instagram } from '../Instagram'
import { LinkedIn } from '../LinkedIn'
import { Star } from '../Star'
import { BirthdayCake } from '../BirthdayCake'
import { BuildYourOwnCard } from '../BuildYourOwnCard'
import { Calendar } from '../Calendar'
import { Candy } from '../Candy'
import { CatalogCard2 } from '../CatalogCard2'
import { ContactCard } from '../ContactCard'
import { ContactGroup } from '../ContactGroup'
import { CustomBrand } from '../CustomBrand'
import { CustomCard } from '../CustomCard'
import { HandWritten } from '../HandWritten'
import { MultiCardCampaign } from '../MultiCardCampaign'
import { PaperPlane2 } from '../PaperPlane2'
import { PersonalTouch } from '../PersonalTouch'
import { PhotoDrop } from '../PhotoDrop'
import { VirtualAssistance } from '../VirtualAssistance'
import { Gift2 } from '../Gift2'
import { GiftCard } from '../GiftCard'
import { LargeCalendar } from '../LargeCalendar'
import { PromptingsPoweredBySoc } from '../PromptingsPoweredBySoc'

// tslint:disable-next-line: cyclomatic-complexity score 55
export const Icon: React.FC<IconProps> = ({
  size = 24,
  color = '#000',
  fill,
  name,
  onClick,
  opacity,
}) => {
  const props = {
    size,
    color,
    fill,
    name,
    onClick,
    opacity,
  }

  switch (props.name) {
    case '10_card':
      return <Cards10 {...props} />
    case '15_card':
      return <Cards15 {...props} />
    case '20_card':
      return <Cards20 {...props} />
    case '25_card':
      return <Cards25 {...props} />
    case '30_card':
      return <Cards30 {...props} />
    case 'brochure':
      return <Brochure {...props} />
    case 'cancel':
      return <Cancel {...props} />
    case 'card':
      return <Card {...props} />
    case 'card_error':
      return <CardError {...props} />
    case 'check':
      return <Check {...props} />
    case 'contact':
      return <Contact {...props} />
    case 'danger':
      return <Danger {...props} />
    case 'database':
      return <Database {...props} />
    case 'double_check':
      return <DoubleCheck {...props} />
    case 'edit':
      return <Edit {...props} />
    case 'empty_gift':
      return <EmptyGift {...props} />
    case 'gift':
      return <Gift {...props} />
    case 'heartfelt':
      return <Heartfelt {...props} />
    case 'info':
      return <Info {...props} />
    case 'multiple_cards':
      return <MultipleCards {...props} />
    case 'package':
      return <Package {...props} />
    case 'paper_plane':
      return <PaperPlane {...props} />
    case 'paper_plane_flying':
      return <PaperPlaneFlying {...props} />
    case 'paper_plane_with_heart':
      return <PaperPlaneWithHeart {...props} />
    case 'replace':
      return <Replace {...props} />
    case 'search':
      return <Search {...props} />
    case 'shopping_cart':
      return <ShoppingCart {...props} />
    case 'upload':
      return <Upload {...props} />
    case 'user':
      return <User {...props} />
    case 'v_check':
      return <VCheck {...props} />
    case 'x':
      return <X {...props} />
    case 'smile_card':
      return <SmileCard {...props} />
    case 'smile_card_w_hearts':
      return <SmileCardWHearts {...props} />
    case 'smile_card_w_heart':
      return <SmileCardWHeart {...props} />
    case 'solopreneur':
      return <SoloPreneur {...props} />
    case 'businesspreneur':
      return <BusinessPreneur {...props} />
    case 'entrepreneur':
      return <EntrePreneur {...props} />
    case 'card_pack':
      return <CardPack {...props} />
    case 'time_block':
      return <TimeBlock {...props} />
    case 'exp_calendar':
      return <ExpCalendar {...props} />
    case 'check_mark':
      return <CheckMark {...props} />
    case 'ticket':
      return <Ticket {...props} />
    case 'automated_card':
      return <AutomatedCard {...props} />
    case 'campaign_card':
      return <CampaignCard {...props} />
    case 'group_send_card':
      return <GroupSendCard {...props} />
    case 'schedule_card':
      return <ScheduleCard {...props} />
    case 'multi_touch_card':
      return <MultiTouchCard {...props} />
    case 'catalog_card':
      return <CatalogCard {...props} />
    case 'enhanced_storage':
      return <EnhancedStorage {...props} />
    case 'premium_storage':
      return <PremiumStorage {...props} />
    case 'photo_drop_card':
      return <PhotoDropCard {...props} />
    case 'personal_signature':
      return <PersonalSignatures {...props} />
    case 'personal_hand_writing':
      return <PersonalHandWriting {...props} />
    case 'essential_packs':
      return <EssentialPacks {...props} />
    case 'google_play_store':
      return <GooglePlayStore {...props} />
    case 'apple_store':
      return <AppleStore {...props} />
    case 'promptings_logo':
      return <PromptingsLogo {...props} />
    case 'play_button':
      return <PlayButton {...props} />
    case 'soc_logo':
      return <SOCLogo {...props} />
    case 'promptings_logo_full':
      return <PromptingsLogoFull {...props} />
    case 'facebook':
      return <FaceBook {...props} />
    case 'instagram':
      return <Instagram {...props} />
    case 'linked_in':
      return <LinkedIn {...props} />
    case 'star':
      return <Star {...props} />
    case 'birthday_cake':
      return <BirthdayCake />
    case 'build_your_own_card':
      return <BuildYourOwnCard />
    case 'calendar':
      return <Calendar />
    case 'candy':
      return <Candy />
    case 'catalog_card_2':
      return <CatalogCard2 />
    case 'contact_card':
      return <ContactCard />
    case 'contact_group':
      return <ContactGroup />
    case 'custom_brand':
      return <CustomBrand />
    case 'custom_card':
      return <CustomCard />
    case 'hand_written':
      return <HandWritten />
    case 'multi_card_campaign':
      return <MultiCardCampaign />
    case 'paper_plane_2':
      return <PaperPlane2 />
    case 'personal_touch':
      return <PersonalTouch />
    case 'photo_drop':
      return <PhotoDrop />
    case 'virtual_assistance':
      return <VirtualAssistance />
    case 'gift_2':
      return <Gift2 />
    case 'gift_card':
      return <GiftCard />
    case 'large_calendar':
      return <LargeCalendar />
    case 'promptings_powered_by_soc':
      return <PromptingsPoweredBySoc />
  }
}
