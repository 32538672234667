import {
  Button,
  ComparisonIncludesList,
  ComparisonItemProps,
  Dialog,
  Div,
  Flex,
  Text,
  Transition as QDSTransition,
  TransitionProps,
  UpsaleDialog,
} from '@sendoutcards/quantum-design-ui'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import connect from 'src/redux/connect'
import { openLaunchPage, openPlans } from 'src/redux/actions/routeAliases'
import { getCardCatalog } from 'src/redux/selectors/catalog'
import { CardCatalog } from 'src/catalog/types'
import Future from 'src/utils/Future'
import CreateAccountForm from 'src/promo/components/CreateAccount'
import {
  AccountFragment,
  AccountInput,
  AddressInput,
  CommitPurchase,
  CommitPurchaseData,
  CreateAccount,
  CreateAccountData,
  CreatePurchase,
  CreatePurchaseData,
  GetPlans,
  GetPlansData,
  GetUpsaleDialogMarketingContent,
  GetUpsaleDialogMarketingContentData,
  UpdateAccount,
  UpdateAccountData,
} from 'src/graphql'
import InnerPaymentSheet, {
  UpsaleResponseData,
} from '../components/InnerPaymentSheet'
import { openApp } from 'src/helpers/appHelpers'
import { Transition } from 'src/chrome'
import { PromoContext } from '../context/promoContext'
import { performOperation } from 'src/app/api'
import useActions from 'src/hooks/useActions'
import {
  EmailValidationStep,
  VerifyClient,
} from 'src/chrome/VerifyClient/VerifyClient'
import { ValidationError } from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'
import ReCAPTCHA from 'react-google-recaptcha'
import { Portal } from 'src/chrome/portal/portal'
import { parseError } from 'src/utils/parseError'
import { useWindowSize } from '@react-hook/window-size'
import { UpsaleOptionType } from '@sendoutcards/quantum-design-ui/dist/src/portfolios/soc/upsaleDialog/upsaleOptions'
import { PlansDescription } from 'src/marketing/plans/types'
import { AppLinks } from 'src/pricing_page/components/appLinks/AppLinks'

type PromoProps = {
  promo?: string
  sponsorId?: string
  forcedSponsorId?: string
  openPlans: () => void
  cardCatalog: Future<CardCatalog>
  isMobile: boolean
  onClose?: () => void
  planId?: string
  checkoutOnly?: boolean
}

type Step =
  | 'SelectingPlan'
  | 'CreatingAccount'
  | 'PurchasingPlan'
  | 'PurchaseSuccessful'

type AddonData = { stripeId: string }
type OptionType = UpsaleOptionType & { addonData?: AddonData }

const visibleOptions: OptionType[] = [
  {
    title: '$20.00/10 Card Challenge',
    subtitle:
      'Send 10 Heartfelt Prompting Cards anywhere in the world for a one-time cost of $20, stamps included. Cards good for 20 days.',
    optionId: '122',
  },
  {
    title: '$37.00/Month Heartfelt',
    subtitle: '20 Heartfelt Prompting Cards - stamps included!',
    featureCaption: 'Top Seller',
    optionId: '6',
  },
  {
    title: '$97.00/Month Heartfelt Plus',
    subtitle: 'Up to 5 Heartfelt Prompting Cards per day - stamps included!',
    featureCaption: 'Most Popular',
    optionId: '128',
  },
  {
    title: '$147.00/Month Heartfelt Plus Gifts',
    subtitle:
      'Up to 5 Heartfelt Prompting Cards per day and 5 Best Seller Gifts per month - stamps and shipping included!',
    featureCaption: 'Incredible Value',
    optionId: '129',
  },
]

const comparisonItems: { [key: string]: ComparisonItemProps[] } = {
  '122': [
    {
      title: '10 Card Credits can be Applied to:',
      description: 'Heartfelt Promptings Cards - stamps included',
      isIncluded: true,
    },
    {
      title: 'Contact Manager',
      description:
        'Store your contacts information - such as their address, birthdays, special occasions, reminders, include notes, and get a history of every card sent.',
      isIncluded: true,
    },
    {
      title: 'Premium Storage',
      description:
        'Premium storage allows you to save unlimited cards, pictures, campaigns, and contacts.',
      isIncluded: true,
    },
  ],
  '6': [
    {
      title: '20 Monthly Heartfelt Prompting Cards (stamps included)',
      description:
        'Send 20 Heartfelt Prompting Cards a month, stamps included. We stuff, stamp, and mail them for you immediately!',
      isIncluded: true,
    },
    {
      title: '20% Discount on Gifts',
      description:
        'Each gift that you add to greeting cards gets you even more savings!',
      isIncluded: true,
    },
    {
      title: '$1.75 Additional Cards',
      description:
        'Each additional card or System Card, pay just $1.75. Stamps not included.',
      isIncluded: true,
    },
    {
      title: 'Contact Manager',
      description:
        'Store your contacts information - such as their address, birthdays, special occasions, reminders, include notes, and get a history of every card sent.',
      isIncluded: true,
    },
    {
      title: 'Premium Storage',
      description:
        'Premium storage allows you to save unlimited cards, pictures, and contacts.',
      isIncluded: true,
    },
  ],
  '128': [
    {
      title: 'Up to 5 Heartfelt Prompting Cards Daily! (Stamps included)',
      description: 'Send 5 Heartfelt Prompting Cards per day, stamps included.',
      isIncluded: true,
    },
    {
      title: '30% Discount on Gifts',
      description:
        'Each gift that you add to greeting cards gets you even more savings!',
      isIncluded: true,
    },
    {
      title: 'Premier Shipping (20% off standard shipping)',
      description:
        'Get even more savings when you ship your greeting card with a gift. We have you covered!',
      isIncluded: true,
    },
    {
      title: '$1.50 Additional Cards',
      description:
        'Each additional card or System Card, pay just $1.50. Stamps not included.',
      isIncluded: true,
    },
    {
      title: 'Contact Manager',
      description:
        'Store your contacts information - such as their address, birthdays, special occasions, reminders, include notes, and get a history of every card sent!',
      isIncluded: true,
    },
    {
      title: 'Premium Storage',
      description:
        'Premium storage allows you to save unlimited cards, pictures, and contacts.',
      isIncluded: true,
    },
  ],
  '129': [
    {
      title: 'Up to 5 Heartfelt Prompting Cards Daily! (Stamps included)',
      description:
        'Send 1-5 Heartfelt Prompting Cards per day, stamps included.',
      isIncluded: true,
    },
    {
      title: 'Up to 5 Best Seller Gifts Monthly (Shipping included)',
      description: 'Send 1-5 Best Seller Gifts per month, stamps included.',
      isIncluded: true,
    },
    {
      title: '30% Discount on Gifts',
      description:
        'Each gift that you add to greeting cards gets you even more savings!',
      isIncluded: true,
    },
    {
      title: 'Premier Shipping (20% off standard shipping)',
      description:
        'Get even more savings when you ship your greeting card with a gift. We have you covered!',
      isIncluded: true,
    },
    {
      title: '$1.25 Additional Cards',
      description:
        'Each additional card or System Card, pay just $1.25. Stamps not included.',
      isIncluded: true,
    },
    {
      title: 'Contact Manager',
      description:
        'Store your contacts information - such as their address, birthdays, special occasions, reminders, include notes, and get a history of every card sent!',
      isIncluded: true,
    },
    {
      title: 'Premium Storage',
      description:
        'Premium storage allows you to save unlimited cards, pictures, and contacts.',
      isIncluded: true,
    },
  ],
}

const getPlan = (planId: string) => {
  return visibleOptions.find(option => option.optionId === planId)
}

const updateAccount = async (
  account: AccountInput,
  onSuccess: (account: AccountFragment) => void,
  onFailure: (error?: Error) => void,
) => {
  performOperation<UpdateAccountData>(
    UpdateAccount({ account: account }),
    result => {
      if (result.value) {
        onSuccess(result.value.updateAccount.account)
      } else {
        onFailure(result.error)
      }
    },
  )
}
const commitPurchase = (
  purchaseIntentId: string,
  onSuccess: () => void,
  onFailure: (error?: Error) => void,
) => {
  performOperation<CommitPurchaseData>(
    CommitPurchase({ purchaseIntentId: purchaseIntentId }),
    result => {
      if (result.value && result.value.commitPurchase.paid) {
        onSuccess()
      } else {
        onFailure(result.error)
      }
    },
  )
}
const createPurchase = (
  stripeId: string,
  onSuccess: (purchaseIntentId: string) => void,
  onFailure: (error?: Error) => void,
) => {
  performOperation<CreatePurchaseData>(
    CreatePurchase({
      itemsToPurchase: [{ itemCode: stripeId, quantity: 1 }],
    }),
    result => {
      if (result.value) {
        onSuccess(result.value.createPurchase.purchaseIntent.id)
      } else {
        onFailure(result.error)
      }
    },
  )
}

const Promo: React.FC<PromoProps> = props => {
  const {
    promo,
    forcedSponsorId,
    sponsorId,
    openPlans,
    cardCatalog,
    isMobile,
    onClose,
    planId,
    checkoutOnly,
  } = props
  // Local Component State
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [upsaleResponseData, setUpsaleResponseData] = useState<
    UpsaleResponseData | undefined
  >(undefined)
  const [isSaving, setIsSaving] = useState(false)

  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const [upsaleCopy, setUpsaleCopy] = useState({ title: '', description: '' })
  const finalSponsorId = forcedSponsorId ?? sponsorId ?? '9070'

  const initialPlanId = planId ?? promo
  const [selectedPlan, setSelectedPlan] = useState(initialPlanId ?? '122')

  const url = new URL(window.location.href)
  const isCheckoutOnly =
    checkoutOnly ?? url.searchParams.get('checkoutOnly') !== null

  // Context State
  const { state, dispatch } = useContext(PromoContext)
  const {
    account,
    create: {
      form,
      isCheckingUsername,
      isUsernameTaken,
      isCheckingEmail,
      emailValidation: { isEmailTaken },
    },
    payment: { form: paymentForm, shouldHaveExpenseBucket, expenseReload },
  } = state

  const initialStep = isCheckoutOnly
    ? account
      ? 'PurchasingPlan'
      : 'CreatingAccount'
    : 'SelectingPlan'
  const [step, setStep] = useState<Step>(initialStep)

  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [shouldVerifyAccount, setShouldVerifyAccount] = useState(false)
  const [validationFailure, setValidationFailure] = useState<ValidationError>()
  const [isTransitionDialogOpen, setIsTransitionDialogOpen] = useState(false)
  const [transition, setTransition] = useState<TransitionProps>({
    title: '',
    isLoading: false,
  })
  const actions = useActions()
  const [deviceWidth, _] = useWindowSize()

  const [allOptions, setAllOptions] = useState<OptionType[]>(visibleOptions)
  const getAllOptions = useCallback(() => {
    performOperation<GetPlansData>(GetPlans(), result => {
      if (!result.error && result.value) {
        const plans = result.value.plans.reduce((acc: OptionType[], plan) => {
          const plans = acc
          const planDescription = PlansDescription.fromPlanFragment(plan)
          const description = planDescription.description

          const visibleOption = visibleOptions.find(
            e => e.optionId === planDescription.id,
          )

          // The $20 for 10 should always be treated as a sub
          const forcedSubscriptions = ['15305']

          const addonData =
            plan.isAddon && !forcedSubscriptions.includes(plan.stripeId)
              ? { stripeId: plan.stripeId }
              : undefined

          const option = description?.upsale
            ? {
                title: description.upsale.title,
                subtitle: description.upsale.subtitle,
                isFeatured: description.upsale.isFeatured,
                optionId: planDescription.id,
                addonData: addonData,
              }
            : visibleOption
            ? visibleOption
            : description
            ? {
                title: description.plan,
                subtitle: description.description,
                isFeatured: description.isFeatured,
                optionId: planDescription.id,
                addonData: addonData,
              }
            : undefined

          if (option) {
            return [...plans, option]
          } else {
            return plans
          }
        }, [])
        setAllOptions(plans)
      }
    })
  }, [setAllOptions])
  useEffect(() => {
    getAllOptions()
  }, [getAllOptions])

  const shownOptions: UpsaleOptionType[] = (() => {
    if (visibleOptions.find(e => e.optionId === selectedPlan)) {
      return visibleOptions
    } else {
      const option = allOptions.find(e => e.optionId === selectedPlan)
      return [...(option ? [option] : []), ...visibleOptions]
    }
  })()

  // Note: ReCaptcha is throwing itself at the end of the background scrollable page for unknown reasons.
  const resetCaptchaPosition = `
    body { overflow-y: hidden; }
    body #site > div:first-child {
      display: none;
    }
  `

  type EventNameType = 'postcard_promo_subscription' | 'postcard_promo_account'

  const sendToCIO = (
    eventName: EventNameType,
    account: { id: string; email: string },
  ) => {
    const cioIdentify = async () =>
      window._cio.identify({
        id: account.id,
        email: account.email,
      })

    cioIdentify().then(() => window._cio.track(eventName))
  }

  const handleSelectedOption = (optionId: string) => {
    setSelectedPlan(getPlan(optionId)?.optionId ?? '42')
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      setIsRedirecting(false)
      openPlans()
    }
  }

  // It's actually called when the "back" button is pressed on the checkout.
  const removeSelectedOption = !isCheckoutOnly
    ? () => {
        setStep('SelectingPlan')
      }
    : undefined

  const onSubmitCreateAccountForm = async (verificationCode?: string) => {
    if (isCheckingUsername || isCheckingEmail) return

    if (!verificationCode) {
      setShouldVerifyAccount(true)
    } else {
      setShouldVerifyAccount(false)

      const token = process.env.REACT_APP_RECAPTCHA_KEY
        ? await recaptchaRef?.current?.executeAsync()
        : undefined

      setIsSaving(true)
      setValidationFailure(undefined)

      const userInfoUpdate: AccountInput = {
        ...form,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        sponsorGenealogyId: finalSponsorId,
      }
      try {
        performOperation<CreateAccountData>(
          CreateAccount({
            account: userInfoUpdate,
            verificationCode: verificationCode,
            recaptchaToken: token,
          }),
          result => {
            result.match(
              value => {
                const validation = value.createAccount.validation
                if (
                  validation &&
                  validation.__typename ===
                    'ValidateEmailVerificationCodeFailure'
                ) {
                  setValidationFailure({
                    type: validation.failureType,
                    message: validation.message,
                  })
                  setShouldVerifyAccount(true)
                } else {
                  if (value.createAccount.account) {
                    dispatch({
                      type: 'SET_ACCOUNT',
                      payload: { account: value.createAccount.account },
                    })
                    actions.saveToken(value.createAccount.account.token)
                    onAccountUpdated(value.createAccount.account)
                    setStep('PurchasingPlan')
                  }
                }
                setIsSaving(false)
                setShowSuccessDialog(true)
              },
              error => {
                setIsTransitionDialogOpen(true)
                setTransition({
                  title: 'There was a problem creating your account',
                  subtitle: `For further assistance, contact the Customer Success team at (801) 463-3800 to resolve this issue. Report error code: "${error.toString()}"`,
                  isLoading: false,
                  loadedStatus: 'error',
                })
                setIsSaving(false)
                setTimeout(() => {
                  setIsTransitionDialogOpen(false)
                }, 15000)
              },
            )
          },
        )
      } catch (error) {
        setIsTransitionDialogOpen(true)
        setTransition({
          title: parseError(error),
          isLoading: false,
          loadedStatus: 'error',
        })
        setIsSaving(false)
        setTimeout(() => {
          setIsTransitionDialogOpen(false)
        }, 15000)
      } finally {
        recaptchaRef.current?.reset()
      }
    }
  }

  const onAccountUpdated = (updatedAccount: AccountFragment) => {
    dispatch({
      type: 'SET_ACCOUNT',
      payload: { account: updatedAccount },
    })
  }

  const updateUpsaleResponseData = (
    upsaleResponseData: UpsaleResponseData | undefined,
  ) => {
    setUpsaleResponseData(upsaleResponseData)
  }

  const setIsUpdatingAccount = (isUpdatingAccount: boolean) => {
    dispatch({
      type: 'SET_IS_UPDATING_ACCOUNT',
      payload: { isUpdatingAccount },
    })
  }
  // Plan upgrade is successfull, lets open the user account on soc-front
  const onPurchaseSuccessful = (accessToken: string) => {
    sendToCIO('postcard_promo_subscription', {
      id: account?.id ?? '',
      email: account?.email ?? '',
    })
    openApp('', { access_token: accessToken })
    setStep('PurchaseSuccessful')
  }

  const onUpdateAccountError = (error: string) => {
    try {
      const errorMessage = error.split(': ')[1]
      return errorMessage
    } catch {
      return 'Account failed to update. Contact Support for assistance.'
    }
  }

  const onPurchasePlan = () => {
    const firstLastNameData =
      !!account?.firstName && !!account?.lastName
        ? null
        : {
            firstName: paymentForm.firstName,
            lastName: paymentForm.lastName,
          }

    const shippingAddressData =
      account?.shippingAddress && account?.shippingAddress.address1
        ? undefined
        : (paymentForm.shippingAddress as AddressInput)

    const expenseBucket = shouldHaveExpenseBucket
      ? {
          ...expenseReload,
          threshold: expenseReload.threshold / 100,
          isEnabled: true,
        }
      : undefined

    const commonAccountData: AccountInput = {
      shippingAddress: shippingAddressData,
      expenseReload: expenseBucket,
      ...(firstLastNameData ?? {}),
    }

    const handleGraphQLError = (error?: Error) => {
      const errorMessage = error
        ? error.message ?? ((error as unknown) as string)
        : ''
      updateUpsaleResponseData({
        title: 'An error occurred',
        subTitle: onUpdateAccountError(errorMessage),
        isLoading: false,
        loadedStatus: 'error',
      })
    }

    const option = allOptions.find(e => e.optionId === selectedPlan)
    if (option?.addonData) {
      const addonData = option.addonData
      setIsUpdatingAccount(true)
      updateUpsaleResponseData({
        title: 'Making your selection',
        subTitle: 'Thank you for your selection!',
        isLoading: true,
        loadedStatus: undefined,
      })

      // Callback hell? What do you mean?
      updateAccount(
        commonAccountData,
        account => {
          onAccountUpdated(account)
          createPurchase(
            addonData.stripeId,
            purchaseIntentId => {
              commitPurchase(
                purchaseIntentId,
                () => {
                  onPurchaseSuccessful(account.token)
                  updateUpsaleResponseData({
                    title: 'Your selection was made successfully',
                    subTitle: 'Thank you for your purchase',
                    isLoading: false,
                    loadedStatus: 'success',
                  })
                  setIsUpdatingAccount(false)
                },
                error => {
                  handleGraphQLError(error)
                  setIsUpdatingAccount(false)
                },
              )
            },
            error => {
              handleGraphQLError(error)
              setIsUpdatingAccount(false)
            },
          )
        },
        error => {
          handleGraphQLError(error)
          setIsUpdatingAccount(false)
        },
      )
    } else {
      const accountData: AccountInput = {
        plan: selectedPlan,
        ...commonAccountData,
      }

      setIsUpdatingAccount(true)

      updateUpsaleResponseData({
        title: 'Making your selection',
        subTitle: 'Thank you for your selection!',
        isLoading: true,
        loadedStatus: undefined,
      })

      updateAccount(
        accountData,
        account => {
          onAccountUpdated(account)
          onPurchaseSuccessful(account.token)
          updateUpsaleResponseData({
            title: 'Your selection was made successfully',
            subTitle: 'Thank you for your purchase',
            isLoading: false,
            loadedStatus: 'success',
          })
          setIsUpdatingAccount(false)
        },
        error => {
          handleGraphQLError(error)
          setIsUpdatingAccount(false)
        },
      )
    }
  }

  const getSubmitDetails = () => {
    const { shippingAddress } = paymentForm
    switch (step) {
      case 'CreatingAccount':
        return {
          title: isSaving ? 'Creating Account' : 'Create Account',
          onClick: onSubmitCreateAccountForm,
          isDisabled:
            isSaving ||
            !form.username ||
            !form.password ||
            !form.email ||
            isCheckingUsername ||
            isUsernameTaken ||
            isEmailTaken ||
            isCheckingEmail,
        }
      case 'PurchasingPlan':
        return {
          title: 'Purchase',
          onClick: onPurchasePlan,
          isDisabled:
            !paymentForm.firstName ||
            !paymentForm.lastName ||
            !shippingAddress ||
            !shippingAddress.address1 ||
            !shippingAddress.city ||
            !shippingAddress.state ||
            !shippingAddress.postalCode ||
            !shippingAddress.country ||
            !account?.stripeSource,
        }
      case 'SelectingPlan':
        return undefined
      default:
        return {
          title: 'Purchasing',
          onClick: () => {
            if (!account) {
              setStep('CreatingAccount')
            } else {
              setStep('PurchasingPlan')
            }
          },
          isDisabled: false,
        }
    }
  }

  useEffect(() => {
    if (promo) openApp('/pricing', { promo: promo })
  }, [promo])

  useEffect(() => {
    window.scrollTo(0, 0)
    actions.clearToken()
    performOperation<GetUpsaleDialogMarketingContentData>(
      GetUpsaleDialogMarketingContent(),
      result => {
        if (result.error || !result.value) {
          setUpsaleCopy({
            title: 'Simple Transparent Pricing',
            description: 'No Contracts. No Surprise Fees.',
          })
        } else {
          setUpsaleCopy({
            title: result.value.upsaleDialogContent.title,
            description: result.value.upsaleDialogContent.content,
          })
        }
      },
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (showSuccessDialog) {
        setShowSuccessDialog(false)
      }
    }, 650)
  }, [showSuccessDialog])

  if (isRedirecting || !cardCatalog.value) {
    return (
      <Transition
        message="Loading..."
        messageStyle={{ color: 'black', fontWeight: 500 }}
      />
    )
  }

  return (
    <Portal attachToContainerId="site">
      <Dialog isOpen={showSuccessDialog}>
        <Text type="largeBody">Account created successfully!</Text>
      </Dialog>
      {isTransitionDialogOpen && (
        <Dialog isOpen={isTransitionDialogOpen}>
          <QDSTransition {...transition} />
        </Dialog>
      )}
      {shouldVerifyAccount && (
        <VerifyClient
          onClose={() => {
            setShouldVerifyAccount(false)
          }}
          email={form.email}
          confirm={{
            onSubmit: async code => {
              onSubmitCreateAccountForm(code)
            },
          }}
          initialStep={
            validationFailure ? EmailValidationStep.ValidationError : undefined
          }
          validationFailure={validationFailure}
        />
      )}
      {
        <UpsaleDialog
          title={upsaleCopy.title}
          description={upsaleCopy.description}
          insetOverride="x0"
          maxWidth={{ xSmall: '100%', small: 'calc(100% - 150px)' }}
          maxHeight={{ xSmall: '90%', small: 'calc(100% - 150px)' }}
          optionFormLocation="right"
          setIsDialogOpen={() => handleClose()}
          isOpen={true}
          selectableOptions={shownOptions}
          selectedOption={selectedPlan}
          handleSelectedOption={handleSelectedOption}
          submitAction={getSubmitDetails()}
          removeSelectedOption={removeSelectedOption}
          shouldHideSendFreeCard={true}
          acceptSelectedAction={{
            title: 'Continue to Checkout',
            onClick: () => {
              if (!account) {
                setStep('CreatingAccount')
              } else {
                setStep('PurchasingPlan')
              }
            },
          }}
          upsaleContentTitle="Plan Includes"
          checkoutTitle="Great Choice!"
          checkoutDescription="We are glad you found the plan that works best for you. Please confirm below."
          upsaleContent={
            comparisonItems[selectedPlan]?.length > 0 && (
              <ComparisonIncludesList
                isMinified={deviceWidth <= 1017}
                items={comparisonItems[selectedPlan]}
              />
            )
          }
          sponsorId={finalSponsorId}
          checkoutOnly={isCheckoutOnly}
          extraLink={
            checkoutOnly
              ? undefined
              : {
                  title: 'See More Options',
                  onClick: openPlans,
                }
          }
          successComponent={
            typeof upsaleResponseData !== undefined && upsaleResponseData ? (
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                flexDirection={'column'}
                height={'100%'}
              >
                <Div width={'100%'}>
                  <QDSTransition
                    title={upsaleResponseData.title}
                    isLoading={upsaleResponseData.isLoading}
                    subtitle={upsaleResponseData.subTitle}
                    loadedStatus={upsaleResponseData.loadedStatus}
                  />
                </Div>
                {upsaleResponseData.loadedStatus === 'error' && (
                  <Div
                    outset={{ top: '3rem' }}
                    width={'100%'}
                    inset={{ horizontal: 'x2' }}
                  >
                    <Button
                      fullWidth={true}
                      title={'Go back'}
                      onClick={() => setUpsaleResponseData(undefined)}
                    />
                  </Div>
                )}
                <AppLinks />
              </Flex>
            ) : (
              undefined
            )
          }
        >
          {step === 'CreatingAccount' && (
            <CreateAccountForm
              isMobile={isMobile}
              isCreatingAccount={isSaving}
              selectedPlan={selectedPlan}
            />
          )}
          {step === 'PurchasingPlan' && account && (
            <InnerPaymentSheet
              planId={selectedPlan}
              onAccountUpdated={onAccountUpdated}
            />
          )}
        </UpsaleDialog>
      }
      {process.env.REACT_APP_RECAPTCHA_KEY && (
        <>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          />
          <style children={resetCaptchaPosition} />
        </>
      )}
    </Portal>
  )
}

export default connect(
  state => {
    return {
      promo: state.promo.promo,
      cardCatalog: getCardCatalog(state),
      sponsorId: state.storefront.sponsorSlug
        ? state.storefront.sponsorSlug
        : state.storefront.storefront?.user.genealogyId.toString(),
      isMobile: state.window.isMobile,
    }
  },
  { openPlans, openLaunchPage },
)(Promo)
